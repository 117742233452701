import React from 'react'
import './App.css'
import {
  Container,
  Box,
  Text,
  GridItem,
  SimpleGrid,
  Spinner
} from '@chakra-ui/react'
import NavBar from './components/NavBar'
import Announcement from './components/Announcement'
import MirrorCard from './components/MirrorCard'

const App = () => {
  const [alert, setAlert] = React.useState(undefined)
  const [mirrors, setMirrors] = React.useState(undefined)
  const [name, setName] = React.useState(undefined)
  React.useEffect(() => {
    const fetchMirrors = async () => {
      const response = await fetch('/mirror/status')
      const data = await response.json()
      if (data.announce !== undefined && data.announce !== '') {
        setAlert(data.announce)
      }
      const result = data.lists.map(name => ({
        name: data[name].name,
        href: data[name].href,
        links: data[name].links,
        status: data[name].status,
        syncUrl: data[name].syncurl,
        lastSync: data[name].lastsync
      }))
      setMirrors(result)
      setName(data.mirrorname)
    }
    fetchMirrors()
  }, [])

  return (
    <div>
      <Container maxW='8xl'>
        <NavBar name={name} />
        {alert !== undefined ? (
          <Announcement
            onClose={() => {
              setAlert(undefined)
            }}
          >
            {alert}
          </Announcement>
        ) : null}
        <Box
          p={5}
          mt={5}
          maxW='full'
          maxH='full'
          borderWidth='1px'
          borderRadius='lg'
        >
          <Text fontWeight={600} fontSize='4xl'>
            Mirrors
          </Text>
          <SimpleGrid columns={[1, null, 2]} gap={5}>
            {mirrors !== undefined ? (
              mirrors.map(
                ({ name, href, links, status, syncUrl, lastSync }) => (
                  <GridItem w='full' key={name}>
                    <MirrorCard
                      name={name}
                      href={href}
                      links={links}
                      status={status}
                      syncUrl={syncUrl}
                      lastSync={lastSync}
                    />
                  </GridItem>
                )
              )
            ) : (
              <Spinner />
            )}
          </SimpleGrid>
        </Box>
        <Box
          p={5}
          mt={5}
          // maxW='full'
        >
          <Text>
            Site made with ❤️ by{' '}
            <a href='https://github.com/Helloyunho'>
              <Text color='blue.500' style={{ display: 'inline-block' }}>
                Helloyunho
              </Text>
            </a>
          </Text>
        </Box>
      </Container>
    </div>
  )
}

export default App
