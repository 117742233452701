import React from 'react'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  CloseButton
} from '@chakra-ui/react'
import PropTypes from 'prop-types'

const Announcement = ({ children, onClose }) => (
  <Alert mt={5} status='info' borderRadius='lg'>
    <AlertIcon />
    <AlertDescription>{children}</AlertDescription>
    <CloseButton
      position='absolute'
      right='8px'
      top='8px'
      onClick={() => {
        onClose(undefined)
      }}
    />
  </Alert>
)

Announcement.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func
}

export default Announcement
