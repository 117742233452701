import React from 'react'
import { CheckIcon, CloseIcon, RepeatIcon } from '@chakra-ui/icons'
import { Box, HStack, Text, useColorMode } from '@chakra-ui/react'
import PropTypes from 'prop-types'

const MirrorCard = ({ name, href, links, status, syncUrl, lastSync }) => {
  /* eslint-disable indent */
  const statusColor =
    status === 'ACTIVE'
      ? 'green.400'
      : status === 'SYNC'
      ? 'purple.500'
      : 'red.600'
  /* eslint-enable indent */
  const stautsText =
    status === 'ACTIVE' ? 'Active' : status === 'SYNC' ? 'Syncing' : 'Error'
  const StatusIcon =
    status === 'ACTIVE' ? CheckIcon : status === 'SYNC' ? RepeatIcon : CloseIcon
  const { colorMode } = useColorMode()
  return (
    <a href={href}>
      <Box p={5} borderWidth='1px' borderRadius='lg' overflow='hidden'>
        <HStack spacing='2' alignItems='center'>
          <Text fontWeight={600} fontSize='3xl'>
            {name}
          </Text>
          <HStack spacing='1' alignItems='center'>
            {links.map(({ rel, href }) => (
              <a href={href} key={rel}>
                <Text color='blue.500'>{rel}</Text>
              </a>
            ))}
          </HStack>
        </HStack>
        <Text color='gray.500'>
          Status: <StatusIcon color={statusColor} />{' '}
          <Text color={statusColor} display='inline-block'>
            {stautsText}
          </Text>
        </Text>
        <Text color='gray.500'>
          From:{' '}
          <a href={syncUrl} style={{ display: 'inline-block' }}>
            <Text color='blue.500'>{syncUrl}</Text>
          </a>
        </Text>
        <Text color='gray.500'>
          Last update:{' '}
          <Text
            color={colorMode === 'dark' ? 'white' : 'black'}
            display='inline-block'
          >
            {new Date(lastSync).toString()}
          </Text>
        </Text>
      </Box>
    </a>
  )
}

MirrorCard.propTypes = {
  name: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      rel: PropTypes.string,
      href: PropTypes.string
    })
  ).isRequired,
  status: PropTypes.string.isRequired,
  syncUrl: PropTypes.string.isRequired,
  lastSync: PropTypes.number.isRequired
}

export default MirrorCard
