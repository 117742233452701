import React from 'react'
import {
  Flex,
  Box,
  Text,
  Spacer,
  useColorMode,
  IconButton,
  HStack
} from '@chakra-ui/react'
import { SunIcon, MoonIcon } from '@chakra-ui/icons'
import PropTypes from 'prop-types'

const NavBar = ({ name }) => {
  const { colorMode, toggleColorMode } = useColorMode()
  const Icon = colorMode === 'dark' ? SunIcon : MoonIcon
  return (
    <header>
      <Flex>
        <Box pt='5'>
          <a href='.'>
            <Text fontWeight={600} fontSize='4xl'>
              {name ?? 'Mirror'}
            </Text>
          </a>
        </Box>
        <Spacer />
        <Box pt='5'>
          <HStack spacing='2' alignItems='center'>
            <IconButton
              aria-label={`Change to ${
                colorMode === 'dark' ? 'light' : 'dark'
              } theme`}
              icon={<Icon />}
              onClick={toggleColorMode}
            />
          </HStack>
        </Box>
      </Flex>
    </header>
  )
}

NavBar.propTypes = {
  name: PropTypes.string
}

export default NavBar
